import { createSlice } from '@reduxjs/toolkit';
import { submissionShareLinkApi } from '@/lib/submissionShareLink/submissionShareLinkAPI';
import { groupApi } from './groupAPI';

interface InitialState {
  detail: {
    groupId: number;
    name: string;
    isDefault: boolean;
    isGeofenceRadiusCheck: boolean;
    geofenceRadius: number | null;
    subscriptionStartDate: string;
    subscriptionExpiredDate: string;
    nextPaymentDate: string;
    nextPaymentAmount: number;
    seatsCount: number;
    usingSeatsCount: number;
    planType: number;
    paymentType: number;
    nextSeatsCount: number;
    nextPaymentType: number;
    nextPlanType: number;
    creditCardType: string;
    creditCardLastFourNumber: string;
    lastPaymentDate: string;
    customerId: string;
    subscriptionId: string;
    paymentEmail: string;
    users: {
      groupId: number;
      userId: number;
      roleId: number;
      invitingUserId: number | null;
      id: number;
      createdBy: number;
      created: string;
      updatedBy: number;
      updated: string;
      isDelete: boolean;
    }[];
    createdBy: number;
    created: string;
    updatedBy: number;
    updated: string;
    isDelete: boolean;
  };
}

const initialState: InitialState = {
  detail: {
    groupId: 0,
    name: '',
    isDefault: false,
    isGeofenceRadiusCheck: false,
    geofenceRadius: null,
    subscriptionStartDate: '',
    subscriptionExpiredDate: '',
    nextPaymentDate: '',
    nextPaymentAmount: 0,
    seatsCount: 0,
    usingSeatsCount: 0,
    planType: 0,
    paymentType: 0,
    nextSeatsCount: 0,
    nextPaymentType: 0,
    nextPlanType: 0,
    creditCardType: '',
    creditCardLastFourNumber: '',
    lastPaymentDate: '',
    customerId: '',
    subscriptionId: '',
    paymentEmail: '',
    users: [],
    createdBy: 0,
    created: '',
    updatedBy: 0,
    updated: '',
    isDelete: false,
  },
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGeofence: (state, action) => {
      state.detail.geofenceRadius = action.payload.geofenceRadius;
      state.detail.isGeofenceRadiusCheck = action.payload.isGeofenceRadiusCheck;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      groupApi.endpoints.GetGroupDetail.matchFulfilled,
      (state, action) => {
        state.detail = action.payload;
      },
    );
    // 將分享連結的預先資料帶入
    builder.addMatcher(
      submissionShareLinkApi.endpoints.getShareLinkSubmissionPrerequisites
        .matchFulfilled,
      (state, action) => {
        state.detail = action.payload.groupDetail;
      },
    );
  },
});

export const { setGeofence } = groupSlice.actions;

export default groupSlice.reducer;
