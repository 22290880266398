import { ProjectDetail } from '@/lib/projects/Interface';
import { projectsApi } from '@/lib/projects/projectsAPI';
import { submissionShareLinkApi } from '@/lib/submissionShareLink/submissionShareLinkAPI';
import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  detail: ProjectDetail | null;
}

const initialState: InitialState = {
  detail: null,
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      projectsApi.endpoints.getProjectDetail.matchFulfilled,
      (state, action) => {
        state.detail = action.payload;
      },
    );
    // 將分享連結的預先資料帶入
    builder.addMatcher(
      submissionShareLinkApi.endpoints.getShareLinkSubmissionPrerequisites
        .matchFulfilled,
      (state, action) => {
        state.detail = action.payload.projectDetail;
      },
    );
  },
});

export default projectSlice.reducer;
