import {
  Brief,
  SubmissionDetail,
  SubmissionTodoTypeEnum,
} from '@/lib/submissions/submissions.interface';
import { submissionApi } from '@/lib/submissions/submissionsAPI';
import { submissionShareLinkApi } from '@/lib/submissionShareLink/submissionShareLinkAPI';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  pages: any[] | null;
  subFormPages: any[] | null;
  isTrashed: boolean;
  submissionVersions: {
    [submissionId: string]: string[];
  };
  selectedVersions: {
    [submissionId: string]: string;
  };
  submissionVersionsSubForm: {
    [submissionId: string]: string[];
  };
  selectedVersionsSubForm: {
    [submissionId: string]: string;
  };
  isMenuIconVisible: boolean;
  submissionDetail: SubmissionDetail;
  submissionDetailSubForm: any | null;
  tempResource: {
    photos: any[];
    files: any[];
  };
  submissionIds: number[];
  brief: Brief | null;
  copyStepData: any | null;
  processDetails: {
    [submissionId: string]: {
      [version: string]: any;
    };
  };
  formTodoCount: {
    allCount: number;
    myTurnCount: number;
    onGoingCount: number;
    doneCount: number;
  };
  formTodoSubmissionIds: number[];
  formTodoCurrentSubmissionId: number | null;
  formTodoCurrentProjectId: number | null;
  formTodoSubmissionProjectMap: Record<number, number>;
  selectedSubmissionId: number | null;
}

const initialState: InitialState = {
  pages: [],
  subFormPages: [],
  isTrashed: false,
  submissionVersions: {},
  selectedVersions: {},
  submissionVersionsSubForm: {},
  selectedVersionsSubForm: {},
  isMenuIconVisible: false,
  tempResource: {
    photos: [],
    files: [],
  },
  submissionDetail: {
    templateName: '',
    created: '',
    submissionId: 0,
    templateId: 0,
    submitter: {
      userId: 0,
      fullName: '',
      email: '',
      photoUrl: '',
      abbreviationBgColor: '',
    },
    submittedAt: '',
    photos: [],
    files: [],
    referenceNumberStr: '',
    currentStepId: '',
    expirationAt: '',
    validTime: '',
    isLastStep: false,
    validUntilState: 0,
    expirationState: 0,
    updated: '',
    pages: [],
    currentStepActions: [],
  },
  submissionDetailSubForm: {
    created: '',
    submissionId: 0,
    templateId: 0,
    submitter: {
      userId: 0,
      fullName: '',
      email: '',
      photoUrl: '',
      abbreviationBgColor: '',
    },
    submittedAt: '',
    photos: [],
    files: [],
    referenceNumberStr: '',
    currentStepId: '',
    expirationAt: '',
    validTime: '',
    isLastStep: false,
    validUntilState: 0,
    expirationState: 0,
    updated: '',
    pages: [],
  },
  submissionIds: [],
  brief: null,
  copyStepData: null,
  processDetails: {},
  formTodoCount: {
    allCount: 0,
    myTurnCount: 0,
    onGoingCount: 0,
    doneCount: 0,
  },
  formTodoSubmissionIds: [],
  formTodoCurrentSubmissionId: null,
  formTodoCurrentProjectId: null,
  formTodoSubmissionProjectMap: {},
  selectedSubmissionId: null,
};

interface ComponentMapping {
  sourceId: string;
  targetId: string;
  value: any;
}

const copySubmissionValues = (
  sourcePages: any[],
  targetPages: any[],
  currentStepId: string,
): any[] => {
  const updatedPages = JSON.parse(JSON.stringify(targetPages));
  const mappings: ComponentMapping[] = [];

  // 第一步：建立欄位映射關係，但只針對當前步驟
  sourcePages.forEach((sourcePage) => {
    sourcePage.components?.forEach((sourceRow) => {
      sourceRow.components?.forEach((sourceComponent) => {
        // 檢查是否屬於當前步驟
        const isCurrentStepField = sourceComponent.setting?.steps?.some(
          (step: { id: string }) => step.id === currentStepId,
        );

        if (isCurrentStepField) {
          updatedPages.forEach((targetPage) => {
            targetPage.components?.forEach((targetRow) => {
              targetRow.components?.forEach((targetComponent) => {
                const isTargetCurrentStepField =
                  targetComponent.setting?.steps?.some(
                    (step: { id: string }) => step.id === currentStepId,
                  );

                if (
                  isTargetCurrentStepField &&
                  sourceComponent.setting.label ===
                    targetComponent.setting?.label
                ) {
                  mappings.push({
                    sourceId: sourceComponent.id,
                    targetId: targetComponent.id,
                    value: sourceComponent.value,
                  });
                }
              });
            });
          });
        }
      });
    });
  });

  // 第二步：更新目標頁面的值
  mappings.forEach((mapping) => {
    updatedPages.forEach((page, pageIndex) => {
      page.components?.forEach((row, rowIndex) => {
        row.components?.forEach((component, componentIndex) => {
          if (component.id === mapping.targetId) {
            updatedPages[pageIndex].components[rowIndex].components[
              componentIndex
            ] = {
              ...component,
              value: mapping.value,
            };
          }
        });
      });
    });
  });

  return updatedPages;
};

const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    setFilledSubmissionPages: (state, action) => {
      state.pages = action.payload;
    },
    setFilledSubFormPages: (state, action) => {
      state.subFormPages = action.payload;
    },
    setIsTrashed: (state, action) => {
      state.isTrashed = action.payload;
    },
    setSelectedVersion: (
      state,
      action: PayloadAction<{ submissionId: string; version: string }>,
    ) => {
      state.selectedVersions[action.payload.submissionId] =
        action.payload.version;
    },
    setIsMenuIconVisible: (state, action) => {
      state.isMenuIconVisible = action.payload;
    },
    setTempResource: (state, action) => {
      state.tempResource = action.payload;
    },
    setCopyStepData: (state, action) => {
      state.copyStepData = action.payload;
    },
    setCopyStepDataToCurrentSubmission: (state) => {
      if (state.submissionDetail && state.copyStepData) {
        const updatedPages = copySubmissionValues(
          state.copyStepData.pages,
          state.pages ?? [],
          state.submissionDetail.currentStepId,
        );
        state.pages = updatedPages;
      }
    },
    setFormTodoCurrentSubmissionId: (state, action) => {
      state.formTodoCurrentSubmissionId = action.payload;
    },
    setFormTodoSubmissionIds: (state, action) => {
      state.formTodoSubmissionIds = action.payload;
    },
    setFormTodoCurrentProjectId: (state, action) => {
      state.formTodoCurrentProjectId = action.payload;
    },
    setSelectedSubmissionId: (state, action) => {
      state.selectedSubmissionId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionDetail.matchFulfilled,
      (state, action) => {
        state.pages = action.payload.pages ?? [];
        state.submissionDetail = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionDetailSubForm.matchFulfilled,
      (state, action) => {
        state.subFormPages = action.payload.pages ?? [];
        state.submissionDetailSubForm = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionProgress.matchFulfilled,
      (state, action) => {
        const submissionId = action.meta.arg.originalArgs;

        const versions = [
          ...new Set(
            action.payload.stepRecords
              .filter((step) => step.version)
              .map((step) => step.version),
          ),
        ].sort();

        state.submissionVersions[submissionId] = versions as string[];
        state.selectedVersions[submissionId] =
          versions[versions.length - 1] ?? 'A';
      },
    );
    builder.addMatcher(
      submissionShareLinkApi.endpoints.getShareLinkSubmissionProgress
        .matchFulfilled,
      (state, action) => {
        const { submissionId } = action.meta.arg.originalArgs;

        const versions = [
          ...new Set(
            action.payload.stepRecords
              .filter((step) => step.version)
              .map((step) => step.version),
          ),
        ].sort();

        state.submissionVersions[submissionId] = versions as string[];
        state.selectedVersions[submissionId] =
          (versions[versions.length - 1] as string) ?? 'A';
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionProgressSubForm.matchFulfilled,
      (state, action) => {
        const submissionId = action.meta.arg.originalArgs;

        const versions = [
          ...new Set(
            action.payload.stepRecords
              .filter((step) => step.version)
              .map((step) => step.version),
          ),
        ].sort();

        state.submissionVersionsSubForm[submissionId] = versions as string[];
        state.selectedVersionsSubForm[submissionId] =
          versions[versions.length - 1] ?? 'A';
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissions.matchFulfilled,
      (state, action) => {
        state.submissionIds = action.payload.data.map(
          (submission) => submission.submissionId,
        );
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getBrief.matchFulfilled,
      (state, action) => {
        state.brief = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getProcessDetail.matchFulfilled,
      (state, action) => {
        const { submissionId, version } = action.meta.arg.originalArgs;

        // 初始化如果不存在
        if (!state.processDetails[submissionId]) {
          state.processDetails[submissionId] = {};
        }

        // 儲存特定版本的資料
        state.processDetails[submissionId][version] = action.payload;
      },
    );
    builder.addMatcher(
      submissionApi.endpoints.getSubmissionTodos.matchFulfilled,
      (state, action) => {
        if (!action.payload.data) return;
        state.formTodoSubmissionIds = action.payload.data.map(
          (todo) => todo.submissionId,
        );
        state.formTodoSubmissionProjectMap = action.payload.data.reduce(
          (acc, todo) => ({
            ...acc,
            [todo.submissionId]: todo.project.projectId,
          }),
          {},
        );
        const requestType = action.meta.arg.originalArgs.todoType;
        if (requestType === SubmissionTodoTypeEnum.AllTodo) {
          const allCount = action.payload.data.length;
          const myTurnCount = action.payload.data.filter(
            (todo) => todo.todoType === SubmissionTodoTypeEnum.MyTurn,
          ).length;
          const onGoingCount = action.payload.data.filter(
            (todo) => todo.todoType === SubmissionTodoTypeEnum.Ongoing,
          ).length;
          const doneCount = action.payload.data.filter(
            (todo) => todo.todoType === SubmissionTodoTypeEnum.Done,
          ).length;

          state.formTodoCount = {
            allCount,
            myTurnCount,
            onGoingCount,
            doneCount,
          };
        }
      },
    );
  },
});

export const {
  setFilledSubmissionPages,
  setFilledSubFormPages,
  setSelectedVersion,
  setIsMenuIconVisible,
  setIsTrashed,
  setTempResource,
  setCopyStepData,
  setCopyStepDataToCurrentSubmission,
  setFormTodoCurrentSubmissionId,
  setFormTodoSubmissionIds,
  setFormTodoCurrentProjectId,
  setSelectedSubmissionId,
} = submissionsSlice.actions;
export default submissionsSlice.reducer;
